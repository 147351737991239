import React, { useEffect } from 'react'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import { renderNodeRule, StructuredText } from 'react-datocms'
import {
    renderRule,
    isHeading,
    isParagraph,
    isList,
    isLink,
} from 'datocms-structured-text-utils'

import Twitter from '@assets/svgs/twitter.svg'
import Facebook from '@assets/svgs/facebook.svg'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

import List from '@components/List'
import YellowButton from '@components/Buttons/YellowButton'
import WhiteButton from '@components/Buttons/WhiteButton'
import StructuredImage from '@components/StructuredImage'
import StructuredVideo from '@components/StructuredVideo'

import Layout from '@hoc/Layout'
import Seo from '../components/SEO';

import '../styles/lists.scss'
import { getRedirectLanguage } from '../utils/getRedirectLanguages'
import { useMenuState } from '../context'

const BlogPost = ({ pageContext, location }) => {
    const {
        heading,
        articleContent,
        articleImage,
        meta,
        category,
        excerpt,
        locale,
        seo,
        seoMetaTags
    } = pageContext

    const { setMenuOpen } = useMenuState()

    useEffect(() => {
        setMenuOpen(false)
        return () => setMenuOpen(false)
    }, [])

    const headerImage = getImage(articleImage)
    const convertHeader = convertToBgImage(headerImage)

    const { lightBg } = useStaticQuery(graphql`
        query {
            lightBg: file(
                relativePath: { eq: "images/textures/texture-img-light.png" }
            ) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    `)

    const blogBgImage = getImage(lightBg.childImageSharp.gatsbyImageData)
    const blogBackground = convertToBgImage(blogBgImage)

    const handleScrollTop = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    return (
        <Layout {...{ locale }}>
            <Seo meta={seoMetaTags?.tags} title={seo?.title} />
            <main>
                <BackgroundImage
                    Tag="section"
                    {...convertHeader}
                    preserveStackingContext
                    className="stacking-context-9 notch-right"
                    style={{
                        backgroundPosition: 'bottom'
                    }}
                >
                    <div
                        className="min-h-[550px] md:h-[625px] lg:min-h-[700px] flex items-end justify-start p-8 md:p-10 lg:p-12 lg:pb-16"
                        style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
                    />
                </BackgroundImage>

                <BackgroundImage
                    Tag="section"
                    {...blogBackground}
                    className="stacking-context-8"
                    style={{
                        backgroundRepeat: 'repeat',
                        backgroundSize: '800px',
                    }}
                >
                    <div className="min-h-[80vh] py-20 -mt-8 relative">
                        <div className="absolute top-12 right-[10%] flex flex-col flex-nowrap gap-1">
                            <span className="w-3 h-0.5 rounded-full bg-darks5" />
                            <span className="w-3 h-0.5 rounded-full bg-darks5" />
                        </div>
                        <div className="border-t border-b border-darks1 relative overflow-hidden">
                            <div className="grid grid-cols-1 lg:grid-cols-18 max-w-screen-2xl mx-auto py-12 gap-12 lg:gap-0 lg:py-16 w-11/12 lg:w-full">
                                <div className="lg:col-start-2 lg:col-end-10 lg:flex lg:items-start lg:flex-col lg:flex-nowrap lg:justify-end">
                                    <span className="bg-darks5 bg-opacity-70 py-1 px-5 w-auto text-gray font-body tracking-widest">
                                        {meta.firstPublishedAt}
                                    </span>
                                    <h1 className="mt-6 font-display text-5xl md:text-6xl lg:text-7xl text-darks3 uppercase max-w-[600px]">
                                        {heading}
                                    </h1>
                                </div>
                                <div className="relative lg:col-start-11 lg:col-end-18">
                                    <div className="clipped-img max-w-[475px] lg:max-w-none bg-darks5 p-12 pb-8 pl-16 relative">
                                        {category && (
                                            <div className="border-b border-blue">
                                                <span className="bg-blue text-offwhite uppercase font-body tracking-widest py-0.5 px-4 block" style={{ width: 'fit-content' }}>
                                                    {JSON.parse(category)[0]}
                                                </span>
                                                <p className="my-8 text-gray tracking-wide uppercase font-display">
                                                    {excerpt}
                                                </p>
                                            </div>
                                        )}
                                        <div className="mt-6 flex flex-row justify-between items-center">
                                            <h2 className="uppercase font-display text-gray opacity-40 text-6xl">
                                                SHARE
                                            </h2>
                                            <div className="flex flex-row flex-nowrap items-center justify-center gap-4">
                                                <TwitterShareButton
                                                    url={location.href}
                                                    title={heading}
                                                    width={30}
                                                    height={30}
                                                >
                                                    <Twitter
                                                        width={30}
                                                        height={30}
                                                        className="text-gray fill-current hover:text-blue transition-all"
                                                    />
                                                </TwitterShareButton>
                                                <FacebookShareButton
                                                    url={location.href}
                                                    quote={heading}
                                                >
                                                    <Facebook
                                                        width="30"
                                                        height="30"
                                                        className="text-gray fill-current hover:text-blue transition-all"
                                                    />
                                                </FacebookShareButton>
                                            </div>
                                        </div>

                                        <div className="absolute bottom-3 right-40 flex flex-col flex-nowrap gap-1">
                                            <span className="shadow-blue bg-blue w-2 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-2 h-0.5 rounded-full" />
                                        </div>

                                        <div className="absolute bottom-12 right-3 flex flex-col flex-nowrap gap-1">
                                            <span className="shadow-blue bg-blue w-3 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-3 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-3 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-3 h-0.5 rounded-full" />
                                        </div>

                                        <div className="absolute right-44 top-3 flex flex-row flex-nowrap gap-1">
                                            <span className="shadow-blue bg-blue w-0.5 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-0.5 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-0.5 h-0.5 rounded-full" />
                                        </div>

                                        <div className="absolute bottom-16 left-3 flex flex-col flex-nowrap gap-1">
                                            <span className="shadow-blue bg-blue w-1 h-0.5 rounded-full" />
                                            <span className="shadow-blue bg-blue w-1 h-0.5 rounded-full" />
                                        </div>

                                        <div className="absolute top-3 left-10 bg-blue shadow-blue w-4 h-1" />
                                    </div>

                                    <div className="absolute hidden -left-6 top-10 lg:flex flex-col flex-nowrap gap-1">
                                        <span className="w-2 h-0.5 rounded bg-darks5" />
                                        <span className="w-2 h-0.5 rounded bg-darks5" />
                                    </div>

                                    <div className="absolute -bottom-4 right-1 hidden lg:flex flex-col flex-nowrap gap-1">
                                        <span className="w-1 h-1 rounded-full bg-darks5" />
                                        <span className="w-1 h-1 rounded-full bg-darks5" />
                                    </div>
                                </div>
                            </div>

                            <div className="absolute w-full h-full z-[-10] top-0">
                                <div className="relative w-full h-full">
                                    <StaticImage
                                        src="../assets/images/drips/drip_texture_03.png"
                                        alt=""
                                        className="!absolute -top-0.5 left-0 h-11"
                                        objectFit="contain"
                                    />
                                    <div className="absolute top-3 right-3">
                                        <span className="h-1 w-24 bg-darks5 rounded block z-20" />
                                        <StaticImage
                                            src="../assets/images/drips/drip_texture_04.png"
                                            alt=""
                                            className="!absolute top-0.5 h-11 !z-[-10]"
                                            objectFit="contain"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-content relative">
                            <div className="py-12 lg:py-16 lg:grid lg:grid-cols-18 text-darks1">
                                <StructuredText
                                    data={articleContent}
                                    renderBlock={({ record }) => {
                                        switch (record.__typename) {
                                            case 'DatoCmsArticleImage':
                                                return (
                                                    <StructuredImage
                                                        image={record.image}
                                                        alt="Something"
                                                    />
                                                )
                                            case 'DatoCmsArticleLinebreak':
                                                return (
                                                    <div className="flex flex-row items-center mt-12 mb-20 gap-2 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                                        <span className="h-0.5 w-14 bg-darks5" />
                                                        <span className="h-px w-full bg-gray block" />
                                                    </div>
                                                )
                                            case 'DatoCmsArticleVideo':
                                                return (
                                                    <StructuredVideo
                                                        {...record}
                                                    />
                                                )
                                            default:
                                                return null
                                        }
                                    }}
                                    customNodeRules={[
                                        renderNodeRule(
                                            isHeading,
                                            ({
                                                node,
                                                adapter: { renderNode },
                                                children,
                                                key,
                                            }) => {
                                                switch (node.level) {
                                                    case 1:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-5xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                    case 2:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-4xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                    case 3:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-3xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                    case 4:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-2xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                    case 5:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                    default:
                                                        return renderNode(
                                                            `h${node.level}`,
                                                            {
                                                                key,
                                                                className:
                                                                    'font-display uppercase text-lg my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                            },
                                                            children
                                                        )
                                                }
                                            }
                                        ),
                                        renderNodeRule(
                                            isParagraph,
                                            ({
                                                node,
                                                adapter: { renderNode },
                                                children,
                                                key,
                                            }) => {
                                                return renderNode(
                                                    `p`,
                                                    {
                                                        key,
                                                        className:
                                                            'font-body my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                                    },
                                                    children
                                                )
                                            }
                                        ),
                                        renderNodeRule(
                                            isList,
                                            ({ node, children, key }) => {
                                                return (
                                                    <div className="col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                                        <ul className="custom-list">
                                                            {children}
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        ),
                                        renderNodeRule(
                                            isLink,
                                            ({
                                                node,
                                                adapter: { renderNode },
                                                children,
                                                key,
                                            }) => {
                                                return renderNode(
                                                    `a`,
                                                    {
                                                        key,
                                                        className:
                                                            'text-orange',
                                                        href: node.url,
                                                    },
                                                    children
                                                )
                                            }
                                        ),
                                    ]}
                                />
                                <div className="col-span-full relative mt-20">
                                    <div className="lg:grid lg:grid-cols-18 auto-rows-auto col-start-2 col-end-18 w-11/12 max-w-screen-2xl lg:w-full mx-auto">
                                        <div className="flex flex-col flex-nowrap lg:col-start-1 lg:col-end-19 lg:grid lg:grid-cols-18 gap-12 lg:gap-0 relative">
                                            <div className="flex flex-col gap-1 flex-nowrap absolute left-[40%] top-16">
                                                <span className="bg-darks5 w-3 h-1 rounded-full" />
                                                <span className="bg-darks5 w-3 h-1 rounded-full" />
                                            </div>
                                            <div className="bg-darks5 clipped-box p-10 lg:col-start-2 lg:col-end-7 relative max-w-[500px] lg:max-w-none mx-auto lg:mx-0">
                                                <p className="font-display uppercase text-2xl text-offwhite mb-6">
                                                    Any feedback? Please share
                                                    it with us in our discord
                                                    server
                                                </p>
                                                <YellowButton
                                                    text="Join Discord"
                                                    link="https://discord.com/invite/thecycle"
                                                    isExternal={true}
                                                />

                                                <div className="bg-blue shadow-blue w-4 h-1 absolute top-3 left-10" />

                                                <div className="absolute flex flex-row gap-1 flex-nowrap top-3 right-3">
                                                    <span className="bg-blue rounded-full w-1 h-1 shadow-blue" />
                                                    <span className="bg-blue rounded-full w-1 h-1 shadow-blue" />
                                                    <span className="bg-blue rounded-full w-1 h-1 shadow-blue" />
                                                </div>

                                                <div className="absolute flex flex-col flex-nowrap gap-1 bottom-3 left-3">
                                                    <span className="bg-blue rounded-full w-1 h-1 shadow-blue" />
                                                    <span className="bg-blue rounded-full w-1 h-1 shadow-blue" />
                                                </div>
                                            </div>
                                            <div className="col-start-12 col-end-17 flex flex-col justify-between py-6 gap-6 max-w-[500px] w-full lg:max-w-none mx-auto lg:mx-0">
                                                <div className="flex flex-row items-center justify-between flex-wrap">
                                                    <h2 className="uppercase font-display text-darks1 text-6xl">
                                                        SHARE
                                                    </h2>
                                                    <div className="flex flex-row flex-nowrap items-center justify-between gap-4">
                                                        <TwitterShareButton
                                                            url={location.href}
                                                            title={heading}
                                                            width={30}
                                                            height={30}
                                                        >
                                                            <Twitter
                                                                width={30}
                                                                height={30}
                                                                className="text-darks1 hover:text-orange fill-current transition-all"
                                                            />
                                                        </TwitterShareButton>
                                                        <FacebookShareButton
                                                            url={location.href}
                                                            quote={heading}
                                                        >
                                                            <Facebook
                                                                width="30"
                                                                height="30"
                                                                className="text-darks1 hover:text-orange fill-current transition-all"
                                                            />
                                                        </FacebookShareButton>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center justify-between flex-wrap lg:flex-nowrap gap-6">
                                                    <WhiteButton
                                                        text="Back"
                                                        link={`${locale.toLowerCase() !== ('en' || undefined) ? `/${locale.toLowerCase()}/news-and-media` : `/news-and-media`}`}
                                                    />
                                                    <YellowButton
                                                        text="Back to top"
                                                        click={handleScrollTop}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="absolute w-full h-full top-0 left-0 z-[-10]">
                                        <div className="relative w-full h-full">
                                            <div className="absolute top-1/2 w-full h-full overflow-hidden">
                                                <span className="w-full h-[1px] bg-darks5 block opacity-60" />
                                                <StaticImage
                                                    src="../assets/images/drips/drip_texture_03.png"
                                                    alt=""
                                                    className="!absolute -top-0.5 left-0 h-9 !z-[-10]"
                                                    objectFit="contain"
                                                />

                                                <div className="absolute top-3 right-3">
                                                    <span className="h-1 w-24 bg-darks5 rounded block z-20" />
                                                    <StaticImage
                                                        src="../assets/images/drips/drip_texture_04.png"
                                                        alt=""
                                                        className="!absolute top-0.5 h-11 !z-[-10]"
                                                        objectFit="contain"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="absolute top-0 left-0 w-full h-full z-[-10] hidden lg:block">
                                <div className="relative w-full h-full">
                                    <div className="absolute top-4 left-20 flex flex-row flex-nowrap items-center gap-2">
                                        <span className="w-1 h-1 rounded bg-darks5" />
                                        <span className="w-1 h-1 rounded bg-darks5" />
                                        <span className="w-1 h-1 rounded bg-darks5" />
                                    </div>

                                    <div className="flex flex-col flex-nowrap gap-1 absolute top-4 right-[20%]">
                                        <span className="w-[35px] h-[3px] rounded bg-darks3" />
                                        <span className="block h-[10px] w-[35px] rounded white-black-box absolute -bottom-4 opacity-60" />
                                    </div>

                                    <span className="absolute top-4 right-[11%] w-6 h-1 bg-darks5" />

                                    <div className="flex flex-col flex-nowrap gap-1 absolute top-4 right-[8%]">
                                        <div className="flex flex-row flex-nowrap gap-1">
                                            <span className="w-3 h-0.5 bg-darks5 rounded-full" />
                                            <span className="w-0.5 h-0.5 bg-darks5 rounded-full" />
                                        </div>
                                        <span className="w-3 h-0.5 bg-darks5 rounded-full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </main>
        </Layout>
    )
}

export default BlogPost
