import React from 'react';
import ReactPlayer from 'react-player';

const StructuredVideo = ({ addClipMask, externalAsset, id, isExternal, videoAsset }) => {
    const renderVideoPlayer = (isExternal) => {
        if (isExternal) {
            return (
                <div className={`structured-player-wrapper my-12 ${addClipMask ? 'clipped-img' : null}`}>
                    <ReactPlayer url={externalAsset.url} controls={true} width="100%" height="100%" className="react-player" />
                </div>
            )
        } else {
            return (
                <div className={`video-wrapper my-12 ${addClipMask ? 'clipped-img' : null}`}>
                    <video
                        controls={true}
                        playsInline
                        className=""
                    >
                        <source src={videoAsset.video.mp4Url} type="video/mp4" />
                        <img
                            src={videoAsset.video.thumbnailUrl}
                            title="Your browser does not support the <video> tag"
                            alt={videoAsset.alt ? videoAsset.alt : 'Blog post video alt text'} />
                    </video>
                </div>
            )
        }
    }

    return (
        <div className="w-full col-span-full grid grid-cols-18 relative">
            <div className="col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                {renderVideoPlayer(isExternal)}
            </div>
        </div>
    )
}

export default StructuredVideo;